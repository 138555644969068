<script>
import {
  Carousel,
  Slide
} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
// import Footer from "@/components/footer";
import referralApi from "@/apis/referralApi";
import storage from '../../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../../date";
// import Navbar2 from "../../components/navbar2.vue";
import blogApi from '../../apis/blogApi';
// import Navbar from "../../components/navbar.vue";
// import Navbar3 from "../../components/navbar3.vue";

import Navbar from "@/views/new-view/components/navbar";
import Footer from "@/views/new-view/components/footerBar";

/**
 * Index-1
 */
export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/how-it-work' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar,
  },
  created() {
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    } else {

    }
  }
};
</script>

<template>
  <div>
    <Navbar v-if="!login"/>
    <Navbar v-else/>


    <!-- 顶部 -->
    <section class="bg-half-170 d-table w-100 pb-0" style="padding-top: 100px;">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">How It Work</h4>
<!--            <p class="text-muted mx-auto mb-0">-->
<!--              If you could not find an answer to your questions,please contant our customer support-->
<!--            </p>-->
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <p style="text-align: center;">&nbsp;&nbsp;
          <img src="@/assets/tmp/howitwork.png">
        </p>
      </div>
    </section>



    <Footer/>
    <!-- Footer End -->
<!--    <Switcher/>-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
